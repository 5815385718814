import React, { useState, useContext, useEffect } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button, Table, Modal } from "react-bootstrap";
import { Spin } from "antd";

import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../context";
import API from "../../utils/api";
import {
  baseUrl,
  getAscSortOrder,
  groupByMultiple,
  momentDate,
} from "../../utils";
import axios from "axios";
import moment from "moment";
const styles = {
  table: {
    border: "1px solid black",
    borderCollapse: "collapse",
  },
  tr: {
    border: "1px solid black",
    borderCollapse: "collapse",
  },
  trBalanceGreen: {
    border: "1px solid black",
    borderCollapse: "collapse",
    background: "#95de64",
  },
  trBalanceRed: {
    border: "1px solid black",
    borderCollapse: "collapse",
    background: "#ffa39e",
  },
  trTotal: {
    border: "1px solid black",
    borderCollapse: "collapse",
    background: "#d9d9d9",
  },
  td: {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
  },
  tdright: {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
    textAlign: "right",
  },
  tdBg: {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
    backgroundColor: "rgb(224 247 211)",
  },
  tdRed: {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
    backgroundColor: "rgb(245 227 221)",
    fontStyle: "italic",
    fontWeight: "bold",
  },
};
const FinancialSummaryPb = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState([]);

  const [fromDate, setFromDate] = useState(
    momentDate(new Date(), "YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(momentDate(new Date(), "YYYY-MM-DD"));

  const [data, setData] = useState([]);
  const [summary, setSummary] = useState([]);

  const [dataView, setDataView] = useState([]);

  const [paymentType, setPaymentType] = useState(false);
  const [groups, setGroups] = useState([]);

  const [groupId, setGroupId] = useState(0);

  const [income, setIncome] = useState([]);
  const [expense, setExpense] = useState([]);
  const [cashInHand, setCashInHand] = useState(0);
  const [bankBalance, setBankBalance] = useState(0);
  const [salesSummary, setSalesSummary] = useState(null);

  useEffect(() => {
    loadGroups();
    //loadData();
  }, []);

  const loadGroups = () => {
    setLoader(true);

    API.get("v1/acc/groups").then((res) => {
      if (res["data"].errorcode == "200") {
        setGroups(res["data"].data);
      }
      setLoader(false);
    });
  };

  const loadData = () => {
    setLoader(true);
    setData([]);
    setDataView([]);
    var form = new FormData();
    form.append("group_id", groupId);
    form.append("staff_id", 0);
    form.append("from_date", fromDate);
    form.append("to_date", toDate);

    API.post("v1/acc/summary_pb", form).then((res) => {
      if (res["data"].errorcode == "200") {
        let ledgers = res["data"].ledgers;
        let sales_summary = res["data"].sales_summary;
        setSalesSummary(sales_summary);

        let data = res["data"].data;
        let opening_balances = res["data"].op_data;

        let summary = data;
        summary.forEach((obj) => {
          obj.opening_balance = 0;
        });

        opening_balances.forEach((item) => {
          let d = summary.findIndex(
            (obj) =>
              obj.account_by == item.account_by &&
              obj.ledger_head_id === item.ledger_head_id
          );
          if (d > -1) {
            summary[d].opening_balance = item.op_amount;
          } else {
            summary.push({
              account_by: item.account_by,
              account_by_group_id: item.account_by_group_id,
              account_by_group_name: item.account_by_group_name,
              account_by_name: item.account_by_name,
              ledger_group_id: item.ledger_group_id,
              ledger_group_name: item.ledger_group_name,
              ledger_head_id: item.ledger_head_id,
              ledger_head_name: item.ledger_head_name,
              net_amount: "0",
              opening_balance: item.op_amount,
            });
          }
        });

        //update purchase op and cur data
        summary.push({
          account_by: "1",
          account_by_group_id: "1",
          account_by_group_name: "CASH",
          account_by_name: "CASH",
          ledger_group_id: "4",
          ledger_group_name: "EXPENSE",
          ledger_head_id: "50",
          ledger_head_name: "PURCHASE OF MATERIALS",
          net_amount: sales_summary["purchase_cur"]
            ? parseInt(sales_summary["purchase_cur"])
            : 0,
          opening_balance: sales_summary["purchase_op"]
            ? parseInt(sales_summary["purchase_op"])
            : 0,
        });

        summary = summary.sort(
          (a, b) => a.ledger_head_name - b.ledger_head_name
        );
        setSummary(summary);
        setData(res["data"].data);
      } else {
        toast.error(res["data"].content || "Error");
      }
      setLoader(false);
    });
  };

  const zeroAmount1 = (amt, withZero = false) =>
    amt && parseInt(amt) != 0
      ? parseInt(parseInt(amt).toFixed())
      : withZero
      ? "0"
      : "0";

  const zeroAmount = (amt, withZero = false) => {
    if (amt && parseInt(amt) !== 0) {
      const formattedAmt = parseInt(amt).toLocaleString("en-IN");
      return formattedAmt;
    } else {
      return withZero ? "0" : "0";
    }
  };

  const getIncome = (item) => {
    return (
      <tr style={styles.tr}>
        <td style={styles.td}>{item.ledger_head_name}</td>
        <td style={styles.tdBg} align="right" width="150">
          {zeroAmount(parseInt(item.ledger_opening_balance), true)}
        </td>
        <td style={styles.tdBg} align="right" width="150">
          {zeroAmount(parseInt(item.total_credit), true)}
        </td>
        <td style={styles.tdBg} align="right" width="150">
          {zeroAmount(
            parseInt(item.ledger_opening_balance) + parseInt(item.total_credit),
            true
          )}
        </td>
      </tr>
    );
  };
  const getTotal = (account_by, type) => {
    var d = [];
    /* 	if (type === 'BANK')
        d = summary.filter(obj => obj.account_by_name === account_by && obj.ledger_group_name !== 'CASH');
      else */
    d = summary.filter(
      (obj) =>
        obj.account_by_name === account_by && obj.ledger_group_name === type
    );

    var opTotal = 0;
    var curTotal = 0;

    d.forEach((item) => {
      if (item.opening_balance)
        opTotal = opTotal + parseInt(item.opening_balance);
      curTotal = curTotal + parseInt(item.net_amount);
    });
    if (type == "INCOME") {
      return (
        <tr style={styles.trTotal}>
          <th style={styles.td}>Total</th>
          <th style={styles.tdright}>{zeroAmount(opTotal)}</th>
          <th style={styles.tdright}>{zeroAmount(curTotal)}</th>
          <th style={styles.tdright}>
            {zeroAmount(parseInt(opTotal) + parseInt(curTotal), true)}
          </th>
        </tr>
      );
    } else if (type == "EXPENSE") {
      var amountInHand = getBalance(account_by, "object");
      return (
        <tr style={styles.trTotal}>
          <th style={styles.td}>Total</th>
          <th style={styles.tdright}>
            {(opTotal + amountInHand.opBalance).toLocaleString("en-IN")}
          </th>
          <th style={styles.tdright}>
            {(curTotal + amountInHand.curBalance).toLocaleString("en-IN")}
          </th>
          <th style={styles.tdright}>
            {(
              parseInt(opTotal) +
              parseInt(curTotal) +
              amountInHand.opBalance +
              amountInHand.curBalance
            ).toLocaleString("en-IN")}
          </th>
        </tr>
      );
    }
  };
  const getBalance = (account_by, resultType = "tr") => {
    var d = [];
    /* 	if (type === 'BANK')
        d = summary.filter(obj => obj.account_by_name === account_by && obj.ledger_group_name !== 'CASH');
      else */
    d = summary.filter(
      (obj) =>
        obj.account_by_name === account_by && obj.ledger_group_name === "INCOME"
    );

    var opTotal = 0;
    var curTotal = 0;

    d.forEach((item) => {
      if (item.opening_balance)
        opTotal = opTotal + parseInt(item.opening_balance);
      curTotal = curTotal + parseInt(item.net_amount);
    });
    //SUBSTRACT EXPENSE
    d = summary.filter(
      (obj) =>
        obj.account_by_name === account_by &&
        obj.ledger_group_name === "EXPENSE"
    );

    var opTotal1 = 0;
    var curTotal1 = 0;

    d.forEach((item) => {
      if (item.opening_balance)
        opTotal1 = opTotal1 + parseInt(item.opening_balance);
      curTotal1 = curTotal1 + parseInt(item.net_amount);
    });
    var finalOpTotal = zeroAmount1(opTotal) - zeroAmount1(opTotal1);
    var finalCurTotal = zeroAmount1(curTotal) - zeroAmount1(curTotal1);

    if (resultType == "tr") {
      return (
        <tr style={styles.trBalanceGreen}>
          <th style={styles.td}>
            {account_by == "CASH" ? "Cash in Hand" : "Cash in Bank"}
          </th>
          <th style={styles.tdright}>{zeroAmount(finalOpTotal.toFixed(0))}</th>
          <th style={styles.tdright}>{zeroAmount(finalCurTotal.toFixed(0))}</th>
          <th style={styles.tdright}>
            {zeroAmount(
              (parseInt(finalOpTotal) + parseInt(finalCurTotal)).toFixed(0)
            )}
          </th>
        </tr>
      );
    } else {
      return {
        opBalance: finalOpTotal,
        curBalance: finalCurTotal,
      };
    }
  };
  const getSalesAmountReceived = () => {
    var sAmountData = summary.find(
      (item) => parseInt(item.ledger_head_id) === 45
    );
    return sAmountData;
  };
  const getOutstanding = (type) => {
    var outstandingAmout = 0;
    if (type === "opening") {
      if (salesSummary && salesSummary.sales_op) {
        var out1 = zeroAmount1(parseInt(salesSummary.sales_op), true);
        if (getSalesAmountReceived())
          var out2 = zeroAmount1(
            parseInt(getSalesAmountReceived().opening_balance),
            true
          );
        outstandingAmout = out1 - out2;
      }
    } else if (type === "current") {
      if (salesSummary && salesSummary.sales_cur) {
        var out1 = zeroAmount1(parseInt(salesSummary.sales_cur), true);
        if (getSalesAmountReceived())
          var out2 = zeroAmount1(
            parseInt(getSalesAmountReceived().net_amount),
            true
          );
        outstandingAmout = out1 - out2;
      }
    } else if (type === "total") {
      if (salesSummary && salesSummary.sales_cur) {
        var out1 =
          parseInt(salesSummary.sales_op ? salesSummary.sales_op : 0.0) +
          parseInt(salesSummary.sales_cur ? salesSummary.sales_cur : 0.0);
        if (getSalesAmountReceived())
          var out2 =
            parseInt(getSalesAmountReceived().opening_balance) +
            parseInt(getSalesAmountReceived().net_amount);
        outstandingAmout = (out1 - out2).toFixed(0);
      }
    }
    return outstandingAmout;
  };
  const getProfitPercentage = (percentage, openingOrCur = "cur") => {
    var cashbalance = getBalance("CASH", "object");
    var profit = 0.0;
    if (cashbalance) {
      if (openingOrCur === "cur")
        profit = parseInt(
          ((parseInt(cashbalance.curBalance) / 100) * percentage).toFixed(0)
        );
      else if (openingOrCur === "op")
        profit = parseInt(
          ((parseInt(cashbalance.opBalance) / 100) * percentage).toFixed(0)
        );
      else if (openingOrCur === "total") {
        var cur = parseInt(
          ((parseInt(cashbalance.curBalance) / 100) * percentage).toFixed(0)
        );
        var op = parseInt(
          ((parseInt(cashbalance.opBalance) / 100) * percentage).toFixed(0)
        );
        profit = parseInt((op + cur).toFixed(0));
      }
    }

    return parseInt(profit.toFixed(0));
  };
  return (
    <div className="container bg-transparent">
      <Spin spinning={loader}>
        <Card style={{ position: "sticky", top: "50px" }}>
          <Card.Body>
            <Row>
              <Col md={12}>
                <h4>Plastic Bank Financial Summary</h4>
                <hr />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={4}>
                <label style={{ fontSize: "11px" }}>Group Name</label>
                <Form.Control
                  as="select"
                  name="group_id"
                  size="sm"
                  onChange={(e) => setGroupId(e.target.value)}
                  required
                >
                  <option value="0">-All Groups-</option>
                  {groups.map((item) => (
                    <option value={item.id}> {item.grp_name} </option>
                  ))}
                  ;
                </Form.Control>
              </Col>
              <Col md={2}>
                <label style={{ fontSize: "11px" }}>From Date</label>
                <Form.Control
                  type="date"
                  max={momentDate(new Date(), "YYYY-MM-DD")}
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  size="sm"
                />
              </Col>
              <Col md={2}>
                <label style={{ fontSize: "11px" }}>To Date</label>
                <Form.Control
                  type="date"
                  max={momentDate(new Date(), "YYYY-MM-DD")}
                  min={fromDate}
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  size="sm"
                />
              </Col>
              <Col md={2}>
                <Button
                  type="button"
                  size="sm"
                  className="mt-4 btn-block"
                  onClick={loadData}
                >
                  View
                </Button>
              </Col>
            </Row>
            <table width="100%">
              <thead>
                <tr style={styles.tr}>
                  <th colSpan={9} style={{ textAlign: "center" }}>
                    Plastic Bank: FINANCIAL SUMMARY
                  </th>
                </tr>

                <tr style={styles.tr}>
                  <th colSpan={9} style={{ textAlign: "center" }}>
                    CASH
                  </th>
                </tr>
                <tr>
                  <th colspan={4}>
                    <table width="100%" algin="center">
                      <tr style={styles.tr}>
                        <th style={styles.td}>
                          <span style={{ fontWeight: "bold", color: "green" }}>
                            Income
                          </span>
                        </th>
                        <th style={styles.td}>Upto previous period</th>
                        <th style={styles.td}>This period</th>
                        <th style={styles.td}>Total</th>
                      </tr>
                      {summary
                        .filter(
                          (obj) =>
                            obj.account_by_name === "CASH" &&
                            obj.ledger_group_name === "INCOME"
                        )
                        .sort((a, b) =>
                          a.ledger_head_name.localeCompare(b.ledger_head_name)
                        )
                        .map((item) => {
                          return (
                            <tr style={styles.tr}>
                              <th style={styles.td}>{item.ledger_head_name}</th>
                              <th style={styles.tdright}>
                                {zeroAmount(
                                  parseInt(item.opening_balance),
                                  true
                                )}
                              </th>
                              <th style={styles.tdright}>
                                {zeroAmount(parseInt(item.net_amount), true)}
                              </th>
                              <th style={styles.tdright}>
                                {zeroAmount(
                                  parseInt(item.opening_balance) +
                                    parseInt(item.net_amount),
                                  true
                                )}
                              </th>
                            </tr>
                          );
                        })}
                      {getTotal("CASH", "INCOME")}
                    </table>
                  </th>
                  <th></th>
                  <th colspan={4} style={{ verticalAlign: "top" }}>
                    <table width="100%" algin="center">
                      <tr style={styles.tr}>
                        <th style={styles.td}>
                          <span style={{ fontWeight: "bold", color: "red" }}>
                            Expenses
                          </span>
                        </th>
                        <th style={styles.td}>Upto previous period</th>
                        <th style={styles.td}>This period</th>
                        <th style={styles.td}>Total</th>
                      </tr>
                      {summary
                        .filter(
                          (obj) =>
                            obj.account_by_name === "CASH" &&
                            obj.ledger_group_name === "EXPENSE"
                        )
                        .sort((a, b) =>
                          a.ledger_head_name.localeCompare(b.ledger_head_name)
                        )
                        .map((item) => {
                          return (
                            <tr style={styles.tr}>
                              <th style={styles.td}>{item.ledger_head_name}</th>
                              <th style={styles.tdright}>
                                {zeroAmount(
                                  parseInt(item.opening_balance),
                                  true
                                )}
                              </th>
                              <th style={styles.tdright}>
                                {zeroAmount(parseInt(item.net_amount), true)}
                              </th>
                              <th style={styles.tdright}>
                                {zeroAmount(
                                  parseInt(item.opening_balance) +
                                    parseInt(item.net_amount),
                                  true
                                )}
                              </th>
                            </tr>
                          );
                        })}
                      {getBalance("CASH")}
                      {getTotal("CASH", "EXPENSE")}
                    </table>
                  </th>
                </tr>
                <tr style={styles.tr}>
                  <th colSpan={9} style={{ textAlign: "center" }}>
                    SALES SUMMARY & GROSS PROFIT
                  </th>
                </tr>
                <tr>
                  <th colspan={4}>
                    <table width="100%" algin="center">
                      <tr style={styles.tr}>
                        <th style={styles.td}>
                          <span style={{ fontWeight: "bold", color: "green" }}>
                            Sales Summary
                          </span>
                        </th>
                        <th style={styles.td}>Upto previous period</th>
                        <th style={styles.td}>This period</th>
                        <th style={styles.td}>Total</th>
                      </tr>

                      <tr style={styles.tr}>
                        <th style={styles.td}>Sales of Materials</th>
                        <th style={styles.tdright}>
                          {zeroAmount(
                            parseInt(
                              salesSummary && salesSummary.sales_op
                                ? salesSummary.sales_op
                                : 0
                            ),
                            true
                          )}
                        </th>
                        <th style={styles.tdright}>
                          {zeroAmount(
                            parseInt(
                              salesSummary && salesSummary.sales_cur
                                ? salesSummary.sales_cur
                                : 0
                            ),
                            true
                          )}
                        </th>
                        <th style={styles.tdright}>
                          {(
                            parseInt(
                              (salesSummary && salesSummary.sales_op
                                ? parseInt(salesSummary.sales_op)
                                : 0.0
                              ).toFixed(0)
                            ) +
                            parseInt(
                              (salesSummary && salesSummary.sales_cur
                                ? parseInt(salesSummary.sales_cur)
                                : 0.0
                              ).toFixed(0)
                            )
                          ).toLocaleString("en-IN")}
                        </th>
                      </tr>
                      <tr style={styles.tr}>
                        <th style={styles.td}>
                          {getSalesAmountReceived() &&
                          getSalesAmountReceived().ledger_head_name
                            ? getSalesAmountReceived().ledger_head_name
                            : ""}
                        </th>
                        <th style={styles.tdright}>
                          {zeroAmount(
                            parseInt(
                              getSalesAmountReceived() &&
                                getSalesAmountReceived().opening_balance
                                ? getSalesAmountReceived().opening_balance
                                : 0
                            ),
                            true
                          )}
                        </th>
                        <th style={styles.tdright}>
                          {zeroAmount(
                            parseInt(
                              getSalesAmountReceived() &&
                                getSalesAmountReceived().net_amount
                                ? getSalesAmountReceived().net_amount
                                : 0
                            ),
                            true
                          )}
                        </th>
                        <th style={styles.tdright}>
                          {zeroAmount(
                            parseInt(
                              getSalesAmountReceived()
                                ? getSalesAmountReceived().opening_balance
                                : 0
                            ) +
                              parseInt(
                                getSalesAmountReceived()
                                  ? getSalesAmountReceived().net_amount
                                  : 0
                              ),
                            true
                          )}
                        </th>
                      </tr>
                      <tr style={styles.tr}>
                        <th style={styles.td}>OutStandings</th>
                        <th style={styles.tdright}>
                          {zeroAmount(
                            parseInt(getOutstanding("opening")),
                            true
                          )}
                        </th>
                        <th style={styles.tdright}>
                          {zeroAmount(
                            parseInt(getOutstanding("current")),
                            true
                          )}
                        </th>
                        <th style={styles.tdright}>
                          {zeroAmount(parseInt(getOutstanding("total")), true)}
                        </th>
                      </tr>
                    </table>
                  </th>
                  <td></td>
                  <th>
                    <table width="100%" algin="center">
                      <tr style={styles.tr}>
                        <th style={styles.td}>
                          <span style={{ fontWeight: "bold", color: "green" }}>
                            Gross Profit
                          </span>
                        </th>
                        <th style={styles.td}>Upto previous period</th>
                        <th style={styles.td}>This period</th>
                        <th style={styles.td}>Total</th>
                      </tr>

                      <tr style={styles.tr}>
                        <th style={styles.td}>Profit Share to managers(65%)</th>
                        <th style={styles.tdright}>
                          {getProfitPercentage(65, "op").toLocaleString(
                            "en-IN"
                          )}
                        </th>
                        <th style={styles.tdright}>
                          {getProfitPercentage(65, "cur").toLocaleString(
                            "en-IN"
                          )}
                        </th>
                        <th style={styles.tdright}>
                          {getProfitPercentage(65, "total").toLocaleString(
                            "en-IN"
                          )}
                        </th>
                      </tr>
                      <tr style={styles.tr}>
                        <th style={styles.td}>Profit Share to group(20%)</th>
                        <th style={styles.tdright}>
                          {getProfitPercentage(20, "op").toLocaleString(
                            "en-IN"
                          )}
                        </th>
                        <th style={styles.tdright}>
                          {getProfitPercentage(20, "cur").toLocaleString(
                            "en-IN"
                          )}
                        </th>
                        <th style={styles.tdright}>
                          {getProfitPercentage(20, "total").toLocaleString(
                            "en-IN"
                          )}
                        </th>
                      </tr>
                      <tr style={styles.tr}>
                        <th style={styles.td}>Profit Share to network(15%)</th>
                        <th style={styles.tdright}>
                          {getProfitPercentage(15, "op").toLocaleString(
                            "en-IN"
                          )}
                        </th>
                        <th style={styles.tdright}>
                          {getProfitPercentage(15, "cur").toLocaleString(
                            "en-IN"
                          )}
                        </th>
                        <th style={styles.tdright}>
                          {getProfitPercentage(15, "total").toLocaleString(
                            "en-IN"
                          )}
                        </th>
                      </tr>
                    </table>
                  </th>
                </tr>
              </thead>
            </table>
          </Card.Body>
        </Card>
      </Spin>
    </div>
  );
};
export default withRouter(FinancialSummaryPb);
