import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Table, Navbar, Container, Nav } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import API from '../../utils/api';
import { MDBInput } from 'mdb-react-ui-kit';
import $ from 'jquery';
import { PSListItem } from '../../comp';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Paginator from 'react-hooks-paginator';
import { triggerFocus } from 'antd/lib/input/Input';
import EditUser from './editUser';
import AddUser from './addUser';
import { baseUrl } from '../../utils';

const UserManagement = (props) => {

	const context = useContext(PsContext);
	const [editModel, setEditModel] = useState(false);
	const [ViewData, setViewData] = useState(false);
	const [user, setUser] = useState([]);

	const [view, setView] = useState([]);
	const [data, changeData] = useState([]);
	const [loading, setIsLoading] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);
	const [searchData, setSearchData] = useState([]);
	const [offset, setOffset] = useState(0);
	const [viewId, setViewId] = useState(0);
	const [password, setPassword] = useState('');
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		listdata();
	}, []);

	const showModel = (item) => {
		setUser(item);
		setEditModel(true);
	}

	const viewModel = (item) => {
		setView(item);
		setViewData(true);
	}




	const showAdd = () => {

		setShowAddModal(true);
	}

	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/user/listallusers').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setSearchData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const onSearchChange = (e) => {
		var d = data;
		let ndata = d.filter(function (item) {
			const name = item.name.toUpperCase();
			const mobile = item.mobile;
			const email = item.email.toUpperCase();
			const s = e.target.value.toUpperCase();
			return (name.indexOf(s) > -1 || mobile.indexOf(s) > -1 || email.indexOf(s) > -1);
		});
		setSearchData(ndata);
	}

	const handleDeleteClick = (item, id) => {
		try {
			if (window.confirm('Do you want to Remove?')) {
				setIsLoading(true);
				API.get('v1/user/delete?id=' + id).then(res => {
					if (res['data'].status == '1') {
						toast.success("User Deleted Successfully..!", "success");
						var d = data;
						var index = d.findIndex(function (obj) {
							return (obj.id == id);
						});
						if (index > -1)
							d.splice(index, 1);
						changeData(d);
						setIsLoading(false);
						listdata();
					}

					else {
						toast.error("Something Went Wrong..!", "danger");
						setIsLoading(false);
					}
				});
			}

		}
		catch (error) {
			setIsLoading(false);
		}
	}

	const onPassHide = (id, e) => {
		var sid = "#" + id;
		$(sid).html('.....');
		setViewId('');
	}

	const onPassView = (psw, id, e) => {
		try {
			setIsLoading(true);
			let formData = new FormData();
			formData.append('password', psw);
			API.post('v1/user/getpassword', formData).then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					setViewId(id);
					setPassword(d);
					setIsLoading(false);
					var sid = "#" + id;
					$(sid).html(d);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}

	}

	return (
		<div className="container" >
			<Navbar bg="white" >
				<Container>
					<Navbar.Brand >List of User</Navbar.Brand>
				</Container>
				<Nav>
					{/*<Button variant="success" size='md' onClick={() => showAdd()} style={{ float: 'right' }}>Add</Button>*/}
				</Nav>
			</Navbar>

			{/*<Col md={2}>
				    <Button variant="success" onClick={()=>{setAddUser(true)}}><i class="fa fa-plus" aria-hidden="true"></i> Add New</Button>
				</Col> */}

			<Row className="mt-3">
				<Col md={8}>
				</Col>
				<Col md={4}>
					<MDBInput
						label="Search "
						type="text"
						onChange={onSearchChange}
					/>
				</Col>
			</Row>
			<br />

			<Row className="text-center">
				<Col md={{ span: 2, offset: 10 }}>
					<Modal show={editModel} size="sm" onHide={() => { setEditModel(false) }} backdrop="static">
						<Modal.Header closeButton >
							<Modal.Title >Edit User</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<EditUser data={user} afterFinish={() => { setEditModel(false) }} afterList={() => listdata()} />
						</Modal.Body>
						<Modal.Footer>

						</Modal.Footer>
					</Modal>
				</Col>
			</Row>
			<Table bordered responsive className="table table-bordered">
				<thead className="text-center">
					<tr>
						<th width="5%">S.No</th>
						<th width="15%"><b>Name</b></th>
						<th width="10%"><b>User Type</b></th>
						<th width="10%"><b>Mobile</b></th>
						<th width="15%"><b>Password</b></th>
						<th width="10%"><b>Manage</b></th>
					</tr>
				</thead>
				<tbody className="text-center">
					{(searchData.map((item, id) => {
						let d = searchData.length;
						return <tr>
							<td>{d - id}</td>
							<td align="left">{item.name}</td>
							<td align="left">{item.type}</td>
							<td>{item.user_name}</td>
							<td>
								{item.pass_word == null ? '' : <b id={item.id}>.....</b>}&emsp;
								{item.pass_word != null ?
									<>
										{viewId == item.id ?
											<Button variant="warning" onClick={(e) => { onPassHide(item.id) }} size="sm"><i className="fas fa-eye-slash"></i></Button>
											: <Button variant="warning" onClick={(e) => { onPassView(item.pass_word, item.id) }} size="sm"><i className="fas fa-eye"></i></Button>
										}
									</>
									: ''}</td>
							<td>
								<Button variant="success" onClick={() => { showModel(item) }} size="sm"><i className="fas fa-edit"></i></Button>
							</td>
						</tr>
					}))}
				</tbody>
			</Table>

			{/*<Modal show={showAddModal} size='sm' onHide={() => setShowAddModal(false)} backdrop="static">
				<Modal.Header closeButton >
					<Modal.Title>Add User</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AddUser afterFinish={() => setShowAddModal(false)} afterList={() => { listdata() }} />
				</Modal.Body>
				</Modal>*/}
		</div>
	);
};
export default UserManagement;