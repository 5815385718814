import React, { useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import $ from 'jquery';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import axios from 'axios';
import Select from 'react-select';
import { integerIndMobile, lettersOnly, baseUrl } from '../../utils';

const AddUser = (props) => {

	const context = useContext(PsContext);
	const [submit, sethandleSubmit] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState([]);
	const [data, changeData] = useState([]);



	useEffect(() => {
		listdata();
	}, []);

	const listdata = () => {
		try {
			setIsLoading(true);
			API.get('v1/group/listgroup').then(res => {
				if (res['data'].status == '1') {
					var d = res['data'].data;
					changeData(d);
					setIsLoading(false);
				}
				else {
					setIsLoading(false);
				}
			});
		}
		catch (error) {
			setIsLoading(false);
		}
	};

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		if (!form.checkValidity() === true) {
			event.stopPropagation();
			setValidated(true);
			return;
		}
		try {
			setBtnLoading(true);
			API.post('v1/user/addcreateuser', $("#editform").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("User Added Successfully..!", "success");
					document.getElementById("editform").reset();
					setBtnLoading(false);

				}
				else {
					toast.error("Something Went Wrong..!", "danger");
					document.getElementById("editform").reset();
				}
				setIsLoading(false);
			});
		}
		catch (error) {
			toast.error("Something Went Wrong..!", "danger");
		}
	};

	const handleChecked = (e) => {
		setSelectedGroup(e);
		console.log(e);
	}

	return (
		<>
			<Row>
				<Col md={4}>

				</Col>
				<Col md={4}>
					<Card>
						<Card.Header>
							<Card.Title>Add Group User Management</Card.Title>
						</Card.Header>
						<Card.Body>
							<Form action="" method="post" id="editform" onSubmit={handleSubmit}>
								<input type="hidden" name="selectedGroup" value={JSON.stringify(selectedGroup)} />
								<Row>
									<Col md={12}>
										<Select className='mt-2' isClearable options={
											data.map((item, i) => {
												return { value: item.id, label: item.grp_name };
											})
										}
											placeholder="Select Group"
											onChange={handleChecked} name="group_type" size="sm" required />
										<MDBInput
											label="Name"
											type="text"
											name="name"
											className='mt-2'
											size="md"
											onKeyPress={lettersOnly}
										/>
										<MDBInput
											label="Mobile"
											type="text"
											name="username"
											className='mt-2'
											size="md"
											onKeyPress={integerIndMobile}
										/>
										<MDBInput
											label="Password"
											type="text"
											name="password"
											className='mt-2'
											size="md"
										/>

									</Col>
								</Row>
								<Button variant='success' className='mt-3' style={{ float: 'right' }} size='sm' type="submit">Submit</Button>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>


		</>
	);
};

export default AddUser;